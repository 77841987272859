<template>
	<div
		class="panel"
		:class="[
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div class="row">
				<div class="col-12 col-lg-6 d-flex">
					<div class="panel_header align-self-center">
						<h2 v-if="isHeaderVisible">{{ block.block_title }}</h2>
						<div class="title" v-if="block.description_top" v-html="block.description_top"></div>
						<div class="description" v-if="block.description_down" v-html="block.description_down"></div>
					</div>
				</div>
				<div class="col-12 col-lg-5 offset-lg-1 mt-3 mt-lg-0">
					<div class="collage">
						<div class="item-1" v-if="block.image_left">
							<img :src="block.image_left.src" :alt="block.image_left.alt"/>
						</div>
						<div class="item-2" v-if="block.image_right">
							<img :src="block.image_right.src" :alt="block.image_right.alt"/>
						</div>
						<div class="item-3" v-if="block.image_down">
							<img :src="block.image_down.src" :alt="block.image_down.alt"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel'

export default {
	name: "DraftAbout5",
	mixins: [panel],
	props: ["block"],
}
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/draft-about-5";
</style>
