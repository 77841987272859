var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",class:[
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container",class:[
			_vm.isBrand? 'sk' : '',
		]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 d-flex"},[_c('div',{staticClass:"panel_header align-self-center"},[(_vm.isHeaderVisible)?_c('h2',[_vm._v(_vm._s(_vm.block.block_title))]):_vm._e(),_vm._v(" "),(_vm.block.description_top)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.block.description_top)}}):_vm._e(),_vm._v(" "),(_vm.block.description_down)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.block.description_down)}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-5 offset-lg-1 mt-3 mt-lg-0"},[_c('div',{staticClass:"collage"},[(_vm.block.image_left)?_c('div',{staticClass:"item-1"},[_c('img',{attrs:{"src":_vm.block.image_left.src,"alt":_vm.block.image_left.alt}})]):_vm._e(),_vm._v(" "),(_vm.block.image_right)?_c('div',{staticClass:"item-2"},[_c('img',{attrs:{"src":_vm.block.image_right.src,"alt":_vm.block.image_right.alt}})]):_vm._e(),_vm._v(" "),(_vm.block.image_down)?_c('div',{staticClass:"item-3"},[_c('img',{attrs:{"src":_vm.block.image_down.src,"alt":_vm.block.image_down.alt}})]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }